import { to as tos } from 'await-to-js';
import router from './router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { getToken, setToken } from '@/utils/auth';
import { isHttp } from '@/utils/validate';
import { isRelogin } from '@/utils/request';
import useUserStore from '@/store/modules/user';
import useSettingsStore from '@/store/modules/settings';
import usePermissionStore from '@/store/modules/permission';
import { getWxMsg } from '@/api/login';


NProgress.configure({ showSpinner: false });
const whiteList = ['/login', '/register', '/social-callback', '/wx-app'];

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  let myString = to.fullPath;
  let index = myString.indexOf('?token=');
  if (index !== -1) {
    // 如果包含，则截取到后面的所有内容
    let tokenValue = myString.substring(index + 7); // 7是'?token='的长度
    setToken(tokenValue);
  }

  if (to.query.code && to.query.corpsecret && to.path == '/wx-app') {
    localStorage.setItem('cli', 'VITE_APP_CLIENT_WXID');

    let data = {
      code: to.query.code,
      corpsecret: to.query.corpsecret,
      clientid: import.meta.env.VITE_APP_CLIENT_WXID
    };
    getWxMsg(data)
      .then((res: any) => {
        setToken(res.data);
        router.push('/');
      })
      .catch((err) => {
        router.push('/login');
      });
  } else {
    if (getToken()) {
      to.meta.title && useSettingsStore().setTitle(to.meta.title as string);
      /* has token*/
      if (to.path === '/login') {
        next({ path: '/' });
        NProgress.done();
      } else {
        if (useUserStore().roles.length === 0) {
          isRelogin.show = true;
          // 判断当前用户是否已拉取完user_info信息
          const [err] = await tos(useUserStore().getInfo());
          if (err) {
            await useUserStore().logout();
            ElMessage.error(err);
            next({ path: '/' });
          } else {
            isRelogin.show = false;
            const accessRoutes = await usePermissionStore().generateRoutes();
            // 根据roles权限生成可访问的路由表
            accessRoutes.forEach((route) => {
              if (!isHttp(route.path)) {
                router.addRoute(route); // 动态添加可访问路由表
              }
            });
            next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
          }
        } else {
          next();
        }
      }
    } else {
      // 没有token
      if (whiteList.indexOf(to.path) !== -1) {
        // 在免登录白名单，直接进入
        next();
      } else {
        next(`/login?redirect=${to.fullPath}`); // 否则全部重定向到登录页
        NProgress.done();
      }
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
